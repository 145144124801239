@import '../../../../theme/theme';

.projectInfoSection {
  padding: 15px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-area: 15px;
  gap: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  @include xsPhoneView {
    box-shadow: none;
    margin-left: 0px;
    padding: 15px 24px 0;
  }
}

.keyInfoContainer,
.additionalInfoContainer,
.projectSummary,
.contactDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 14px;
  color: #2f3336; //to be replaced
  font-size: $fontXSmall;
}

.keyInfoContainer {
  background: rgba(var(--abandonment-background-color-new), 0.1);
}

.additionalInfoContainer {
  background: rgba(var(--more-info-background-color-new), 0.1);
}

.projectSummary {
  background-color: rgba(var(--certification-background-color-new), 0.1);
  gap: 12px;
  height: auto;
}

.contactDetailsContainer {
  background-color: rgba(var(--contactDetail-background-color-new), 0.1);
}

.singleRowInfoContent {
  flex: 1;
  .singleRowInfoTitle {
    font-weight: 700;
    margin-bottom: 4px;
    display: flex;
    gap: 12px;
  }
}

.certificateDataContainer {
  display: flex;
  justify-content: space-between;
  a {
    color: $certificationLinkColorNew;
    font-weight: 700;
    font-size: $fontXXSmall;
  }
}

.keyInfoSubContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.infoIconPopupContainer {
  padding: 12px 16px;
  font-size: $fontXXSmall;
  max-width: 200px !important;
}

.abandonmentTitle {
  display: flex;
  gap: 12px;
}

.siteOwnershipTitle {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  text-transform: capitalize;
  gap: 3px;
}

.siteOwnershipLabelContainer {
  display: flex;
  gap: 5px;
  margin-bottom: 4px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  max-width: 258px;
  span {
    padding: 2px 6px;
    background-color: rgba(var(--more-info-background-color-new), 0.2); //
    border-radius: 4px;
    font-weight: 400;
    font-size: $fontXXSmall;
    white-space: nowrap;
  }
}

.siteOwnershipLabelContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.downloadLabel {
  padding: 2px 9px;
  border-radius: 24px;
  border: 0.4px solid rgba(var(--certification-background-color-new), 0.2);
  background: rgba(var(--certification-background-color-new), 0.15);
  font-weight: 700;
  width: fit-content;
  cursor: pointer;
  a {
    color: rgb(var(--certification-background-color-new));
  }
}
.expenseContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}
.expenseDetails {
  display: flex;
  gap: 6px;
  align-items: center;
  min-width: 50%;
  .expenseYear {
    font-size: $fontXXSmall;
  }
}
.reportsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.downloadIcon {
  display: none;
}

.contactText {
  margin-top: 12.87px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.singleContact {
  display: flex;
  background-color: rgba(var(--secondary-divider-color-new), 0.12);
  border-radius: 8px;
  padding: 0 10.5px;
  min-height: 41px;
  align-items: center;
  gap: 8.5px;

  .icon {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backgroundColor;
    flex-shrink: 0;
  }
  .title {
    font-size: $fontXSmall;
    font-weight: 500;
    color: #2f3336; //to be replaced
  }
  .rightArrow {
    margin-left: auto;
  }
}

@include xsPhoneView {
  .downloadLabel {
    background-color: transparent;
    border: 0px;
    font-weight: 400;
    color: inherit;
    padding: 0;
    cursor: none;
  }
  .expenseDetails {
    width: 100%;
    gap: 32px;
  }
  .downloadIcon {
    display: inline;
    margin-left: auto;
    cursor: pointer;
  }
  .expenseContainer {
    gap: 2px;
  }
  .reportsContainer {
    flex-direction: column;
    gap: 2px;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
}
