.videoContainer {
  width: 100%;
  position: relative;
}

.video > div,
.video > div > iframe {
  border-radius: 13px;
}

.playButtonBackdrop {
  background-color: #ffffff33;
  border-radius: 50%;
  backdrop-filter: blur(4.902973175048828px);
  height: 41px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 3px;
  }
}
